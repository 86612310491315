<template>
  <CCard>
    <CCardBody>
      <!-- <div class="clearfix">
        <button
          type="button"
          class="btn btn-primary float-xl-right"
          @click="navToNewSymptoms"
        >
          New Briefing
        </button>
      </div> -->

      <CDataTable
        :items="items"
        :fields="fields"
        striped
        clickable-rows
        column-filter
        table-filter
        items-per-page-select
        :items-per-page="20"
        sorter
        ref="vuetable"
        pagination
      >
        <template #status-filter>
          <select
            class="form-control form-control-sm"
            @input="
              $refs.vuetable.columnFilterEvent(
                'status',
                $event.target.value,
                'input'
              )
            "
          >
            <option value selected="selected">Any</option>
            <option value="1">Upcoming</option>
            <option value="2">Pending</option>
            <option value="3">Past</option>
            <option value="4">Started</option>
            <option value="5">Past</option>
          </select>
        </template>
        <template #index="data">
          <td>
            {{ data.index + 1 }}
          </td>
        </template>
        <!-- <template #topicName="{item}">
        <td
          v-if="item.topicName"
        >{{ item.topicName  }}</td>
        <td v-else>--</td>
     </template> -->

        <template #briefName="{ item }">
          <!-- <td v-show="item.topicName == null  ">{{ item.topic }}</td>
          <td v-show="item.topic == null ">{{ item.topicName }}</td> -->
          <td class="font-weight-bold" v-if="item.briefName">
            {{ item.briefName }}
          </td>
          <td class="font-weight-bold" v-else>--</td>
        </template>

        <template #firstName="{ item }">
          <td class="font-weight-bold" v-if="item.firstName && item.lastName">
            {{ item.firstName }} {{ item.lastName }}
          </td>
          <td v-else>--</td>
        </template>

        <!-- <template #authorName="{ item }">
                  <td v-if="item.author && item.author.displayName">
                    {{ item.author.displayName }}
                  </td>
                  <td v-else>--</td>
                </template> -->
        <template #lastName="{ item }">
          <td class="font-weight-bold" v-if="item.lastName">
            {{ item.lastName }}
          </td>
          <td v-else>--</td>
        </template>
        <template #createdTime="{ item }">
          <td class="font-weight-bold" v-if="item.createdTime">
            {{
              item.createdTime | dateParse() | dateFormat("MMM D, YYYY hh:mm A")
            }}
          </td>
          <td v-else>--</td>
        </template>
        <template #date="{ item }">
          <td class="font-weight-bold" v-if="item.date">
            {{
              item.date | dateParse("YYYY.MM.DD") | dateFormat("MMM D, YYYY ")
            }}
          </td>
          <td v-else>--</td>
        </template>

        <!-- <template #firstName="{ item }">
          <td v-if="item.firstName">{{ item.firstName }}</td>
          <td v-else>--</td>
        </template>
        <template #lastName="{ item }">
          <td v-if="item.lastName">{{ item.lastName }}</td>
          <td v-else>--</td>
        </template>
        <template #createdTime="{ item }">
          <td v-if="item.createdTime">
            {{
              item.createdTime | dateParse() | dateFormat("MMM D, YYYY hh:mm A")
            }}
          </td>
          <td v-else>--</td>
        </template>
        <template #date="{ item }">
          <td v-if="item.date">
            {{
              item.date | dateParse("DD.MM.YYYY") | dateFormat("MMM D, YYYY ")
            }}
          </td>
          <td v-else>--</td>
        </template> -->

        <template #time="{ item }">
          <td class="font-weight-bold" v-if="item.time">
            {{ item.time | dateParse("HH.mm") | dateFormat("hh:mm A ") }}
          </td>
          <td v-else>--</td>
        </template>
        <template #status="{ item }">
          <td class="font-weight-bold" v-show="item.status == 1">Upcoming</td>
          <td class="font-weight-bold" v-show="item.status == 2">Pending</td>
          <td class="font-weight-bold" v-show="item.status == 3">Past</td>
          <td class="font-weight-bold" v-show="item.status == 4">Started</td>
          <td class="font-weight-bold" v-show="item.status == 5">Past</td>
        </template>
        <template #show_details="{ item }">
          <td class="py-2">
            <CDropdown togglerText="Actions" color="info">
              <CDropdownItem
                :to="{
                  name: 'Briefing',
                  params: { briefingId: item.briefingId, mode: 'view' },
                }"
                >View
              </CDropdownItem>
              <CDropdownItem
                :to="{
                  name: 'Acknowledgements',
                  params: { briefingId: item.briefingId, mode: 'view' },
                }"
                >Acknowledgements
              </CDropdownItem>
              <CDropdownItem @click="navToExportPayments(item.briefingId)">
                Export</CDropdownItem
              >
              <!-- v-if="item.otherBriefName == null"-->
                 <ConfirmationModal
                :parent="$refs.modalArea"
                :description="'Are you sure to Delete'"
                @on:ok="deleteBriefing(item.briefingId)"
              ></ConfirmationModal> 
            </CDropdown>
          </td>
        </template>

        <!-- <template #view="{ item }">
         <td class="py-1">
         <CButton togglerText="View" color="info" size="sm">
              <CDropdownItem
                :to="{
                  name: 'Briefing',
                  params: { briefingId: item.briefingId, mode: 'view' },
                }"
                >View
              </CDropdownItem>
         </CButton>
         
         </td>
        </template> -->
      </CDataTable>
    </CCardBody>
    <div ref="modalArea"></div>
  </CCard>
</template>

<script>
import Vue from "vue";
import ConfirmationModal from "../../containers/ConfirmationModal";
const fields = [
  { key: "index", _style: "min-width:200px", _classes: "font-weight-bold" },
  { key: "briefName", _style: "min-width:200px", _classes: "font-weight-bold" },
  {
    key: "firstName",
    label: "Officer Name",
    _classes: "font-weight-bold",
    _style: "min-width:200px",
  },
  //  { key: "lastName",  _style: "min-width:200px" },
  { key: "status", _classes: "font-weight-bold", _style: "min-width:200px" },
  {
    key: "information",
    _classes: "font-weight-bold",
    _style: "min-width:200px",
  },
  { key: "date", _classes: "font-weight-bold", _style: "min-width:200px" },
  { key: "time", _classes: "font-weight-bold", _style: "min-width:200px" },
  {
    key: "createdTime",
    label: "Created Date & Time",
    _classes: "font-weight-bold",
    _style: "min-width:200px",
  },
  {
    key: "show_details",
    label: "",
    _style: "width:1%",
    sorter: false,
    filter: false,
  },
  //  {
  //   key: "view",
  //   label: "",
  //   _style: "width:1%",
  //   sorter: false,
  //   filter: false,
  // },
];
export default {
  name: "Briefings",
  components: {
    ConfirmationModal,
  },
  data() {
    return {
      items: [],
      fields,
      details: [],
      collapseDuration: 0,
      download: "",
    };
  },
  methods: {
    navToExportPayments(briefingId) {
      var filename = "eMapp.xls";
      this.apiGetSecure(
        process.env.VUE_APP_API_HOST +
          "/emapp/web/secure/Brief/export/" +
          briefingId
      )
        .then((response) => {
          if (response.status === 200) {
            console.log("headers", response.headers);
            // filename = response.headers
            //   .get("Content-Disposition")
            //   .split("filename=")[1];
            // console.log(filename);
            

            return response.blob();
          } else {
            return;
          }
        })
        .then((blob) => {
          // console.log("blob values", blob);
          var downloadUrl = window.URL.createObjectURL(blob);
          // console.log("doenload", downloadUrl);
          var a = document.createElement("a");
          a.href = downloadUrl;
          a.download = filename;
          document.body.appendChild(a);
          a.click();
          a.remove();
        });
    },
    getBriefings() {
      const url =
        process.env.VUE_APP_API_HOST +
        "/emapp/web/secure/get/Briefings?status=0";
      console.log("getBriefings", url);
      this.apiGetSecure(url)
        .then((response) => response.json())
        .then((data) => (this.items = data));
    },

    // downloadItem(briefingId) {
    //   const url =
    //     process.env.VUE_APP_API_HOST +
    //     "/emapp/web/secure/Brief/export/" +
    //     briefingId;
    //   console.log("getBriefings", url);
    //   this.apiGetSecure(url)
    //     .then((response) => response.json())
    //     .then((data) => (this.download = data));
    // },

    deleteBriefing(briefingId) {
      console.log("delete called with", briefingId);
      this.apiGetSecure(
        process.env.VUE_APP_API_HOST +"/emapp/web/secure/delete/briefing/"+briefingId)
        .then((response) => response.json())
        .then((data) => {
          this.getBriefings();
             this.$nextTick(() => {
             this.$refs.vuetable.refresh();
           });
          window.location.reload();
        });
    },
    navToNewSymptoms() {
      this.$router.push({ name: "NewBriefing", params: { mode: "new" } });
    },
  },
  mounted() {
    this.getBriefings();
  },
};
</script>